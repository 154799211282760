<template>
  <div
    class="vx-row h-screen lg:flex w-full no-gutter"
    id="page-login"
  >
    <div
      class="vx-col sm:w-1/1 xs:w-1/1 lg:w-1/2 h-full w-full bg-blue"
    >

      <div class="flex justify-center items-center h-full w-full p-6">
        <div class="vue-qr-reader__container" ref="container">
          <div class="flex justify-center mb-12">
            <vs-button
              :color="isScanner ? 'secondary' : ''"
              class="py-2 px-6 mt-4 text-white"
              @click="isScanner = true">
              {{ $t('Scanner') }}
            </vs-button>
            <vs-button
            :color="!isScanner ? 'secondary' : ''"
              class="py-2 px-6 mt-4 text-white ml-2"
              @click="isScanner = false">
              {{ $t('Camera') }}
            </vs-button>
          </div>
          <!-- scanner -->
          <div v-if="isScanner" class="qr-code-wrapper flex justify-center">
            <form v-on:submit.prevent="onChangeQRValue()">
              <!-- <input type="text" name="qrcode_value" v-model="qrValue" class="input-event-code w-full lg:w-full input-uppercase" ref="inputQRValue" @paste="onChangeQRValue()" @blur="focusInput()"> -->
              <vs-input v-filter-input
                class="input-event-code w-full lg:w-full input-uppercase"
                v-model="qrValue"
                maxlength="6"
                icon-no-border
                icon="icon icon-arrow-right-circle pointer"
                icon-pack="feather"
                :placeholder="$t('Access event code')"
                icon-after="true"
                v-on:icon-click="onChangeQRValue()"
                ref="inputQRValue" @paste="onChangeQRValue()" @blur="focusInput()"
                >
              </vs-input>
            </form>
          </div>

          <div v-if="!isScanner" class="text-white text-3xl font-medium font-base flex justify-center">
            <div>{{ $t('Scan your QR code here') }}</div>
          </div>
          <div v-if="!isScanner" class="qr-code-wrapper">
            <qrcode-stream
              v-if="isShowScanner"
              @error="onError"
              @decode="onDetect"
              :track="trackQrCode"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      class="vx-col sm:w-1/1 xs:w-1/1 lg:w-1/2 h-full w-full bg-white"
    >
      <div class="flex justify-center items-center h-full w-full">
        <img src="@/assets/images/logo/koblatil.png" alt="Kobla" class="pr-5 w-1/3"/>
      </div>
    </div>
    <modal
      class="modal-qr-information"
      size="information-qr"
      :hide-footer="true"
      :show-close="true"
      :isEnableKeyUp="false"
      @close="closeModal"
      v-if="showModalInformation">
      <div slot="header">
      </div>
      <div class="text-4xl text-center" slot="body">

        <div class="flex-container" style="min-height: 70vh;" v-if="isSuccess">
          <div v-if="isAnimation" class="absolute justify-center flex">
            <div class="checkmark-container success">
              <svg class="checkmark draw" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle class="checkmark-circle" cx="26" cy="26" r="25" fill="none"/>
                <path class="checkmark-check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
              </svg>
            </div>
          </div>
          <div v-else>
            <div class="avatar-container">
              <vs-avatar v-if="participant && participant.avatar_thumbnail" class="p-0 m-0" :src="participant.avatar_thumbnail" size="80px" />
              <vs-avatar
                v-if="participant && !participant.avatar_thumbnail"
                class="p-0 m-0"
                :color="getColor(participant.name)" :text="getInitial(participant.name)"
                size="80px"
              />
            </div>
            <div class="text-container">
              Hi, <span class="font-bold text-primary">{{ participant && participant.name }}</span>
            </div>
            <div v-if="participant && participant.status && participant.status === 'Checked-in'" class="status-container">
              {{ messageScan }}
              <div class="mt-2">
                <span class="text-3xl" v-if="participant.table">{{ $t('Your Table') }}: <span class="text-primary font-bold">{{ participant.table }}</span></span>
                <span class="ml-2 text-3xl" v-if="participant.seat">{{ $t('Seat') }}: <span class="text-primary font-bold">{{ participant.seat }}</span></span>
              </div>
            </div>
            <div v-if="participant && participant.status && participant.status === 'Checked-out'" class="status-container">
              {{ messageScan }}
            </div>
          </div>
        </div>
        <div class="flex-container" style="min-height: 60vh;" v-else>
          <div class="icon-container fail">
            <svg class="xmark draw" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
              <circle class="xmark-circle" cx="26" cy="26" r="25" fill="none"/>
              <path class="xmark-x" fill="none" d="M16 16 36 36 M36 16 16 36"/>
            </svg>
          </div>
          <div class="mt-10">{{ $t('Error during check-in. Ensure your QR code is valid and try again') }}</div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal.vue';
import {
  getAxiosErrorMessage, getFirstCharacter, generateColorByAlphabet, duplicateVar,
} from '@/lib/helper';
import eventApi from '@/api/event';
import { QrcodeStream } from 'vue-qrcode-reader';

export default {
  name: 'vue-qr-reader',
  components: {
    Modal,
    QrcodeStream,

  },
  props: {
    useBackCamera: {
      type: Boolean,
      default: true,
    },
    stopOnScanned: {
      type: Boolean,
      default: true,
    },
    drawOnFound: {
      type: Boolean,
      default: true,
    },
    lineColor: {
      type: String,
      default: '#FF3B58',
    },
    lineWidth: {
      type: Number,
      default: 2,
    },
  },
  data() {
    return {
      showPlay: false,
      participant: null,
      isSuccess: false,
      showModalInformation: false,
      result: '',
      error: '',
      isAnimation: true,
      isShowScanner: true,
      messageScan: '',
      isScanner: true,
      qrValue: '',
      timeoutId: null,
      seatUser: '',
      tableUser: '',
    };
  },
  created() {
    this.$i18n.locale = 'en';
  },
  methods: {
    focusInput() {
      setTimeout(() => {
        if (this.$refs.inputQRValue) {
          this.$refs.inputQRValue.$el.querySelector('input').focus();
        }
      }, 50);
    },
    onChangeQRValue() {
      setTimeout(() => {
        if (this.qrValue) {
          const qrValue = duplicateVar(this.qrValue);
          this.scanQR(qrValue);
        }
      }, 500);
    },
    getInitial(str) {
      return getFirstCharacter(str);
    },
    getColor(str) {
      return generateColorByAlphabet(str);
    },
    trackQrCode(detectedCodes, ctx) {
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height); // Clear previous drawings

      detectedCodes.forEach((code) => {
        const { cornerPoints } = code;

        ctx.strokeStyle = 'red';
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(cornerPoints[0].x, cornerPoints[0].y);

        for (let i = 1; i < cornerPoints.length; i++) {
          ctx.lineTo(cornerPoints[i].x, cornerPoints[i].y);
        }

        ctx.lineTo(cornerPoints[0].x, cornerPoints[0].y);
        ctx.stroke();
      });
    },
    onDetect(detectedCodes) {
      this.result = detectedCodes;
      this.scanQR(this.result);
    },
    onError(err) {
      this.error = `[${err.name}]: `;
      if (err.name === 'NotAllowedError') {
        this.error += 'you need to grant camera access permission';
      } else if (err.name === 'NotFoundError') {
        this.error += 'no camera on this device';
      } else if (err.name === 'NotSupportedError') {
        this.error += 'secure context required (HTTPS, localhost)';
      } else if (err.name === 'NotReadableError') {
        this.error += 'is the camera already in use?';
      } else if (err.name === 'OverconstrainedError') {
        this.error += 'installed cameras are not suitable';
      } else if (err.name === 'StreamApiNotSupportedError') {
        this.error += 'Stream API is not supported in this browser';
      } else if (err.name === 'InsecureContextError') {
        this.error += 'Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.';
      } else {
        this.error += err.message;
      }
    },

    closeModal() {
      this.showModalInformation = false;
    },

    updateLocale(locale) {
      this.$i18n.locale = locale;
      document.querySelector('html').setAttribute('lang', locale);
      this.$store.dispatch('setLocale', locale);
    },

    scanQR(code) {
      this.$vs.loading();
      const params = {
        code,
      };

      const callback = (response) => {
        this.isSuccess = true;
        this.isAnimation = true;
        this.updateLocale(response.data?.language);
        this.showModalInformation = true;
        this.participant = response.data;
        this.messageScan = response.message;
        this.$vs.loading.close();
        this.isShowScanner = false;
        setTimeout(() => {
          this.isAnimation = false;
          this.isShowScanner = true;
        }, 2000);

        // Clear the previous timeout if it exists
        if (this.timeoutId) {
          clearTimeout(this.timeoutId);
        }

        // Set a new timeout to hide showModalInformation after 10 seconds
        this.timeoutId = setTimeout(() => {
          this.showModalInformation = false;
        }, 10000);
        this.qrValue = '';
      };

      const errorCallback = (e) => {
        this.showModalInformation = true;
        this.isSuccess = false;
        const message = getAxiosErrorMessage(e);
        console.log(message);
        this.isShowScanner = false;
        setTimeout(() => {
          this.isShowScanner = true;
        }, 2000);
        this.$vs.loading.close();

        // Clear the previous timeout if it exists
        if (this.timeoutId) {
          clearTimeout(this.timeoutId);
        }

        // Set a new timeout to hide showModalInformation after 10 seconds
        this.timeoutId = setTimeout(() => {
          this.showModalInformation = false;
        }, 10000);
        this.qrValue = '';
      };
      eventApi.qrScanner(params, callback, errorCallback);
    },
  },
  mounted() {
    this.focusInput();
  },
  beforeDestroy() {
  },
  watch: {
    isScanner() {
      if (this.isScanner) {
        this.focusInput();
      }
    },
  },
};
</script>

<style scoped>
.flex-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.avatar-container, .text-container, .status-container {
  margin: 1rem 0; /* Add margin to separate each block */
}

.vue-qr-reader__container {
  width: 100%;
  height: auto;
  overflow: hidden; /* Ensure child elements respect the border radius */
}
video,
canvas {
  width: 100%;
  height: auto;
  border-radius: 10px; /* Border radius for video and canvas */
}

.qr-code-wrapper {
  margin: auto;
  position: relative;
  width: 100%;
  max-width: 90vh;
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.success .checkmark, .fail .xmark {
  height: 28vh; /* Size */
  border-radius: 50%;
  display: block;
  stroke-width: 4; /* Thicker stroke */
  stroke: #4CAF50; /* Green color */
  stroke-miterlimit: 10;
  margin: auto auto;
  box-shadow: inset 0px 0px 0px #4CAF50;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.fail .xmark {
  stroke: #F44336; /* Red color */
}

.checkmark-circle, .xmark-circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 4; /* Thicker stroke */
  stroke-miterlimit: 10;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark-check, .xmark-x {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.7s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

.input-code {
  width: 321px; height: 50px; font-size: 28px; border-radius:4px; border: solid 1px white; background-color: transparent; color: white;
  padding-left: 4px;
}

</style>
